import { IonContent, IonPage, IonImg } from '@ionic/react';
  
import { useLocation } from 'react-router-dom';

import Header from './Header';
import Navigation from './Navigation';
import Footer from './Footer';
import './Base.css';
import ChatBot from '../ChatBot';

const Base: React.FC = (props) => {
  const location = useLocation();
  
  return (
    <IonPage id="main-content">
      <IonContent>
        <Header />
       
        <div id="main">
          <Navigation />
          {props.children}
        </div>
        <ChatBot />
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Base;
  