import { createChatBotMessage, createClientMessage, createCustomMessage   } from 'react-chatbot-kit';

import React from 'react';
import parse from 'html-react-parser';
interface IActionProvider {
  createChatBotMessage: ICreateChatBotMessage,
  setState: ISetState,
}

interface ICreateChatBotMessage {
  (message: string, options?:any):void
}

interface ISetState {
  (value:any):void
}

const ActionProvider:React.FC<IActionProvider> = ({ createChatBotMessage, setState, children }) => {
  
  const handleHello = () => {
    const botMessage = createChatBotMessage('Hello. Nice to meet you.');

    setState((prev:any) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));

    
  };

  const askForName = () => {
    const botMessage = createChatBotMessage(`What is your name?`, {
      payload: {}, // any value I want to send to the given widget or message
      delay: 2000,
    });
    setState((prev:any) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const askForNameOnly = (prevMessage:string) => {
    const botMessage = createChatBotMessage(`I am not sure. Your name is not really '${prevMessage}' ? If not, please write only your name.`, {
      payload: {}, // any value I want to send to the given widget or message
      delay: 500,
    });
    setState((prev:any) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };
  
  const handleAbout = () => {
    const botMessage = createChatBotMessage(`More Information about me:`/* Or you connect with me on ` + parse('<a href="https://www.linkedin.com/in/dietmar-braun-806877161">LinkedIn</a>')*/, {
      widget: 'aboutMe',
      payload: {}, // any value I want to send to the given widget or message
      delay: 2500,
    });
    setState((prev:any) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleName = () => {

  };

  const sayDoNotUnderstand = () => {
    const botMessage = createChatBotMessage(`Sorry I didn't understand you. For more help or further questions, please feel free to write to me: post@dietmar-m-braun.de.`, {
      payload: {}, // any value I want to send to the given widget or message
      delay: 500,
    });
    setState((prev:any) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const sayName = (name:string) => {
    const botMessage = createChatBotMessage(`Hello ${name}. Nice to meet you.`, {
      payload: {}, // any value I want to send to the given widget or message
      delay: 500,
    });
    setState((prev:any) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const sayWelcome = () => {
    const botMessage = createChatBotMessage(`To get in touch with me, you can send me an email to post@dietmar-m-braun.de.`/* Or you connect with me on ` + parse('<a href="https://www.linkedin.com/in/dietmar-braun-806877161">LinkedIn</a>')*/, {
      payload: {}, // any value I want to send to the given widget or message
      delay: 500,
    });
    setState((prev:any) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  return (
    <div>
      {React.Children.map(children, (child:any) => {
        return React.cloneElement(child, {
          actions: {
            askForName,
            askForNameOnly,
            handleAbout,
            handleHello,
            handleName, 
            sayDoNotUnderstand,
            sayName,
            sayWelcome,
           
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;