import React, {useState, useEffect} from 'react';
import { IonGrid, IonRow, IonCol, IonIcon } from '@ionic/react';
import { chatbubbleOutline, chatbubbleSharp, settingsOutline } from 'ionicons/icons';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';

import config from './bot/config';
import MessageParser from './bot/Messageparser';
import ActionProvider from './bot/ActionProvider';


const ChatBot: React.FC = (props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    useEffect(() => {
        if(isOpen === true && isInitialized === false){
            setIsInitialized(true);
        }
    }, [isOpen])

    const handleOpenChatBot = (event:any) => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={"chatbot-container" + (isOpen? " open" : " closed")}>
        {isInitialized === true ?  (
            <Chatbot
                config={config}
                messageParser={MessageParser}
                actionProvider={ActionProvider}
            />
        ): null}
          <IonIcon onClick={handleOpenChatBot} className="chatbot-bubble" icon={chatbubbleSharp}></IonIcon>
        </div>
    )
}

export default ChatBot;