import React from 'react';
import { homeOutline, menu, mail, mailOpen, mailOpenOutline } from 'ionicons/icons';
import {IonIcon,} from '@ionic/react';
  
  import { useLocation } from 'react-router-dom';
  import {logoLinkedin, logoGithub, logoGitlab, logoNpm, logoTwitter, archiveOutline, archiveSharp, bagSharp, bagOutline, bookmarkOutline, clipboard, clipboardOutline, clipboardSharp, heartOutline, heartSharp, logOutOutline, logOutSharp, mailOutline, mailSharp, menuOutline, menuSharp, newspaperOutline, newspaperSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';

  interface INavigationProps {

  }

  interface INavigation {
    title?: string,
    items: INavigationItem
  }

  interface INavigationItem {
    href:string,
    name: string,
    class?: string,
    active?: boolean,
  }

  const Navigation: React.FC<INavigationProps> = (props) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const handleToggleClick = (event:any) => {
        setIsOpen(!isOpen);
    };

    const [navigationItems, setNavigationItems] = React.useState<INavigationItem[]>();

    React.useEffect(() => {
        if(navigationItems === undefined){
            setNavigationItems([
                {href: "#about", name: "About Me"},
                {href: "#my-skills", name: "My Skills"},
                {href: "#my-csv", name: "My CV"},
            ]);
        }
    }, [navigationItems]);

    const handleNavItemClick = (event:any) => {
        event.preventDefault();

        let href = event.target.getAttribute("href");
        let elem;

        if(href.includes("#"))
            elem = document.getElementById(href.replace("#", ""));
        else if(href == "")
            elem = document.getElementById('header');
        console.log(elem);
        if(elem)
            elem?.scrollIntoView({ behavior: 'smooth' });

    }

    return (
      <div className={"navigation " + (isOpen ? 'open': 'closed')}>
        <IonIcon icon={menu} className="toggle-nav" onClick={handleToggleClick}></IonIcon>
        <ul className="hide-elements">
            <li><a href="" onClick={handleNavItemClick} ><IonIcon className="home-icon" icon={homeOutline}></IonIcon> Home</a></li>
            {navigationItems?.map((item, index) => {
                return(<li key={'nav-item-link-'+index}><a  className="" href={item.href} onClick={handleNavItemClick} >{item.name}</a></li>);
            })}
        </ul>
        <a className="mail-button" href="mailto:post@dietmar-m-braun.de"><IonIcon icon={mailOpenOutline} /></a>
      </div>
    );
  };
  
  //Menu.defaultProps = defaultProps;
  
  export default Navigation;
  