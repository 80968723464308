import React, {useState, useEffect} from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';

interface listOfskills {
    mainSkills:skills[],
}

interface skills{
    headline:string,
    skills:skill[],
}

interface skill {
    name:string,
    nextIsEmpty?:boolean,
}

const mainSkillList:listOfskills = {
    mainSkills: [
        {
            headline: "Web Engineering",
            skills: [{name:"HTML"},{name:"CSS"},{name:"JavaScript / TypeScript"},{name:"ExpressJS / NodeJS"},{name:"Ionic Framework"},{name:"React"},{name: "MobX", nextIsEmpty: true,},{name:"PHP"},{name:"Symfony"}]
        },
        {
            headline: "Software Engineering",
            skills: [{name:"C#"},{name:"Windows Services"},{name:"WinForms"},{name:"WPF"},{name:"Xamarin / MAUI", nextIsEmpty: true,},{name:"FoxPro Basic", nextIsEmpty:true,},{name:"Postgres"},{name:"MySQL"}]
        },
        {
            headline: "Others",
            skills: [{name:"SASS"},{name:"GIT"},{name:"NPM"},{name:"Github / GitLab"},{name:"Responsive Design", nextIsEmpty: true,},{name:"..."}]
        },
    ]
}

const About: React.FC = () => {
    return (
        <section className="about" id="about">
            <h2>About Me</h2>
            <p className="intro-section">Now is the time to find out more about me, my skills and my talents. Of course, this mostly refers to programming and technical aspects.</p>
            <IonGrid>
                <IonRow>
                    <IonCol className='intro-text' size='3.5' sizeXs='10' offset='2.2' offsetXs='1'>
                        <h3>Get to know me better!</h3>
                        <p>I am a <strong>FRONTEND Software Developer</strong> building Front-end for web. Transforming requirements and wishes into an excellent feeling is always my highest goal.<br/>
                        This procedure is not only available on the web, but should also be available for other applications.</p>
                        <p>My skills are not only limited to the web, like <strong>JavaScript, React, CSS,</strong> etc. I also develop applications and services in <b>C#</b>.<br/>
                        In addition to many <b>Windows services</b>, I have been able to gain some <b>WinForm</b> and <b>WPF applications</b>, as well as experience with <b>Xamarin</b> and <b>.Net MAUI</b>. </p>
                        <p>Since I started my career with <b>PHP</b> and <b>Symfony</b>, I have a lot of knowledge about this as well.</p>
                        <p>Another big goal in all my work is to produce <b>clean code</b>. This makes the life of any developer, mine or someone else's, miraculously easier.</p>
                    </IonCol>
                </IonRow>
                <IonRow id="my-skills">
                    <IonCol className='intro-text' size='3.8'  sizeXs='10' offset='.6' offsetXs='1'>
                        <h3>My Skills</h3>
                        <p>Now let's have a look about my skills:</p>
                        {mainSkillList.mainSkills.map((skillCollection, index) => {
                            return (<div key={'skillCollection-' + index} className="skill-block">
                            <h4>{skillCollection.headline}</h4>
                            <ul className="skill-list">
                            {skillCollection.skills.map((skill, index) => {
                                return <li key={'skill-' + index} className={skill.nextIsEmpty === true ? "next-empty": ""}>{skill.name}</li>
                            })}  
                            </ul>
                        </div>)
                        })}
                    </IonCol>
                </IonRow>

                <IonRow id="my-csv">
                    <IonCol className='intro-text' size='3.5' sizeXs='10' offset='2.2' offsetXs='1'>
                        <h3>My CV</h3>
                        <p>For even more information you can download my CV and get an even better picture of me.</p>
                        <a className="button center" href="Dietmar_Braun_Lebenslauf.pdf" target="_blank">My CV</a>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </section>
    ); 
}

export default About;