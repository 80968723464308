import { useState, useEffect } from 'react';

import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonImg } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab1.css';

import Base from '../components/base/Base';
import ImageIntro from '../components/ImageIntro';
import Intro from '../components/Intro';
import About from '../components/About';
const Home: React.FC = () => {
  const [introImageContainer, setIntroImageContainer] = useState<any[]>([]);
  
  useEffect(() => {

  }, [introImageContainer] )

  return (
    <Base>
        {/*<ImageIntro title="About me"  text='Absolut der Hammer, ich mach das perfekt'/>*/}
        <Intro />
        <About />
    </Base>
  );
};

export default Home;
