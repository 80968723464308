import { createChatBotMessage } from 'react-chatbot-kit';
import WidgetChatAbout from './WidgetChatAbout';


const config = {
  initialMessages: [createChatBotMessage(`Hi! I am Dietmar Braun`)],
  widgets: [
    {
      widgetName: 'aboutMe',
      widgetFunc: (props) => <WidgetChatAbout {...props} />,
      props: undefined,
      mapStateToProps:[]
    },
  ],
  botName: "Dietmar",
  state: {
    myCustomProperty: 'Bikershorts',
  },
  customStyles: {
    botMessageBox: {
      backgroundColor: 'silver',
      color: 'black',
    },
    chatButton: {
      backgroundColor: 'silver',

    },
  },
  customComponents: {
    header: () => <div style={{ fontWeight: 'bold', fontSize: '18px', color: 'rgb(66, 66, 66)', backgroundColor: '#F6AE2D', padding: "10px", borderRadius: "3px" }}>Conversation with Dietmar</div>,
    botAvatar: (props) => <p {...props} style={{position: 'relative', bottom: '1em', right: '.5em', backgroundColor: 'silver', padding: '.4em', marginBottom: '0em', borderRadius: '5em', height: '2em', width: '2em', textAlign: 'center', lineHeight: 1.1, fontSize: '1.5em'}} >D</p>,
    userAvatar: (props) => <p {...props} style={{}} ></p>,
  },
  
};

export default config;