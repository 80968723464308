import React, {useState, useEffect} from 'react';
import { IonGrid, IonRow, IonCol, IonContent, IonSlides, IonSlide, IonImg } from '@ionic/react';

interface IWidgetAbout{
    links:IWidgetAboutLink[]
}

interface IWidgetAboutLink {
    href:string,
    name: string
}

const about:IWidgetAbout = {
    links: [
        {href: "#about", name: "About Me"},
        {href: "#my-skills", name: "My Skills"},
        {href: "#my-csv", name: "My CV"},
    ]
};

const WidgetChatAbout: React.FC = () => {
    const handleClick = (event: any) => {
        event.preventDefault();
        let elem = document.getElementById(event.target.getAttribute("href").replace("#", ""));

        if(elem)
            elem?.scrollIntoView({ behavior: 'smooth' });
    };
    
    return (
        <div className="widget-chat-about">
            <p>Do you want to get to know me better?</p>
            <p>Then take a look at the following points about me.</p>
            <ul style={{listStyle: "none"}}>
            {about.links.map((link, index) => {
                return(<li key={'widget-chat-link-'+index}><a  className="button" href={link.href} onClick={handleClick} >{link.name}</a></li>);
            })}
            </ul>
        </div>
    ); 
}

export default WidgetChatAbout;