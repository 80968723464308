import {
    IonContent,
    IonImg,
    IonGrid, IonCol, IonRow,
    IonFooter,
    IonToolbar,
    IonTitle,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonNote,
  } from '@ionic/react';
  
  import { useLocation } from 'react-router-dom';
  import {logoLinkedin, logoGithub, logoGitlab, logoNpm, logoTwitter, archiveOutline, archiveSharp, bagSharp, bagOutline, bookmarkOutline, clipboard, clipboardOutline, clipboardSharp, heartOutline, heartSharp, logOutOutline, logOutSharp, mailOutline, mailSharp, menuOutline, menuSharp, newspaperOutline, newspaperSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
  import './Menu.css';

  const Footer: React.FC = (props) => {
    const location = useLocation();
    const tdate = new Date();

    return (
      <IonFooter collapse='fade'>
        <IonGrid>
            <IonRow>
                <IonCol className="footer-main"  size="2" sizeMd='5' sizeSm='6' sizeXs='12' offset='2' offsetMd='1' offsetSm='1' offsetXs='0'>
                    <IonTitle>Dietmar M. Braun</IonTitle>
                    
                </IonCol>
                <IonCol  size="3" sizeMd='3' sizeSm='2.5'  sizeXs='4' offset='5' offsetMd='2.5' offsetSm='1.5'  offsetXs='7.8'>
                    <ul className="nav">
                      <li><a href="/imprint" target="_blank">Imprint</a></li>
                    </ul>
                </IonCol>
            </IonRow> 
            <IonRow className="copyright">
              <IonCol size="2" sizeXs='12'  offset='5' offsetXs='0' >
                <p>@ Copyright {tdate.getFullYear()}. Made by <a href="https://dietmar-m-braun.de" target="_blank">Dietmar M. Braun</a></p>
              </IonCol>
            </IonRow>  
        </IonGrid>
      </IonFooter>
    );
  };
  
  //Menu.defaultProps = defaultProps;
  
  export default Footer;
  