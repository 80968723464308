import React, {useState, useEffect} from 'react';
import { IonGrid, IonRow, IonCol, IonContent, IonSlides, IonSlide, IonImg } from '@ionic/react';

import './Intro.css';

const Intro: React.FC = () => {

    return (
        <section className="intro-container">
            <IonGrid >
                <IonRow>
                    <IonCol className='intro-text' size='5.5' sizeMd='6.0' sizeSm='7.0' sizeXs='10.5'  offset='1.5' offsetMd='1.0' offsetXs='.5'>
                        <h1>Hey, I'm Dietmar Braun</h1>
                        <p>A passionate Software Engineer with a strong focus on Web Development. My Favorite actions are Building Web Applications with React.</p>
                    </IonCol>
                    <IonCol className="avatar" size='2.5' sizeLg='2.75' sizeMd='3.5'  sizeSm='3.5' sizeXs='5.5' offset='.75' offsetMd='.5' offsetSm='.5' offsetXs='.1'><IonImg src='assets/img/db_image_original_black_white1_quadrat_degree_trans.png'  /></IonCol>
                </IonRow>
            </IonGrid>
        </section>
    ); 
}

export default Intro;