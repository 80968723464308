import React from 'react';

interface IMessageParser {
  children?: any,
  actions?:any
}



const MessageParser:React.FC<IMessageParser> = ({ children, actions }) => {
  const [name, setName] = React.useState<string>();
  const [askedForName, setAskedForName] = React.useState<boolean>(false);

  React.useEffect(() => {
    if(name === undefined || name === ""){
      setAskedForName(true);
      actions.askForName();
    }
  }, [name])

  const parse = (message:string) => {
    let lowerMessage = message.toLowerCase();

    if(lowerMessage.includes('hello') || lowerMessage.includes('hallo') || lowerMessage.includes('guten tag') || lowerMessage == 'hi')
    {
      actions.handleHello();
    }
    else if(askedForName === true){
      console.log(message);
      if(lowerMessage.length > 30){
        actions.askForNameOnly(message);
      }
      else {
        let realName = message.replaceAll("I am", "").replaceAll("i am", "")
                              .replaceAll("My name is", "").replaceAll("my name is", "")
                              .replaceAll("Mein Name ist", "").replaceAll("mein name ist", "")
                              .replaceAll("Ich bin", "").replaceAll("ich bin", "")
                              .replaceAll("Ich heiße", "").replaceAll("ich heiße", "")
                              .replaceAll("Ich heisse", "").replaceAll("ich heisse", "").split(',')[0];
        
        actions.sayName(realName);
        actions.sayWelcome();
        setAskedForName(false);
        actions.handleAbout();
      }
    } 
    else 
      actions.sayDoNotUnderstand();
    
      
  };


  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions: {
            
          },
        });
      })}
    </div>
  );
};

export default MessageParser;