import {
    IonContent,
    IonImg,
    IonGrid, IonCol, IonRow,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonNote,
  } from '@ionic/react';
  
  import { useLocation } from 'react-router-dom';
  import {logoLinkedin, logoGithub, logoGitlab, logoNpm, logoTwitter, archiveOutline, archiveSharp, bagSharp, bagOutline, bookmarkOutline, clipboard, clipboardOutline, clipboardSharp, heartOutline, heartSharp, logOutOutline, logOutSharp, mailOutline, mailSharp, menuOutline, menuSharp, newspaperOutline, newspaperSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
  import './Menu.css';

  import codInIcon from '../../static/icons/codingame_icon.png';

/*
  interface MenuProps{
    isOpen?: boolean | true
  }
  
  const defaultProps: MenuProps = {
    isOpen: true,
  }
*/
  interface Social {
    title: string,
    url: string,
    iosIcon?: string,
    mdIcon?: string,
    image?: string,
  }
  
  const socialPages: Social[] = [
    {
      title: 'CodInGame',
      url: 'https://www.codingame.com/profile/5352fc174fc05c1aa62805aa340454cd8799365',
      image: codInIcon,
    },
    
    {
      title: 'Github',
      url: 'https://github.com/dietmar-braun',
      iosIcon: logoGithub,
      mdIcon: logoGithub,
    },
    {
      title: 'LinkedIn',
      url: 'https://www.linkedin.com/in/dietmar-braun-806877161/',
      iosIcon: logoLinkedin,
      mdIcon: logoLinkedin,
    },
    
    /*
    {
      title: 'Gitlab',
      url: 'https://gitlab.com',
      iosIcon: logoGitlab,
      mdIcon: logoGitlab,
    },
    {
      title: 'NPM',
      url: 'https://npm.com',
      iosIcon: logoNpm,
      mdIcon: logoNpm,
    },
    */
  ];
  
  const Header: React.FC = (props) => {
    const location = useLocation();
    
    return (
      <IonHeader id="header">
        <IonGrid>
          {/*
          <IonRow className="fixed-nav">
              <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/impressum">Impressum</a></li>
              </ul>
          </IonRow>
          */}
          <IonRow className="corporate-row">
            <IonCol className="round-col header-logo" offset='2.5' offsetMd='1.2' offsetXs='0.2'size="1"  sizeSm='2' sizeXs='2'>
              <a href="/"><IonImg className="" src='assets/icon/logo_db.png' /></a>
            </IonCol>
          
            <IonCol className="round-col" offset='0' offsetMd='.05' offsetSm='.1' sizeLg='8' sizeMd='8' sizeSm='8' sizeXs='9.8' >
              <IonRow>
                <IonCol offset='1' offsetMd='0' offsetXs='0' size='6' sizeMd='8' sizeXs='11' >
                  <p className="headline-one">Dietmar M. Braun</p>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol offset='0' offsetMd='0' offsetXs='0' size='8' sizeMd='8' sizeXs='11'>
                  <p className="headline-two">FRONTEND Software Engineer</p>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol offset='0' offsetMd='0' offsetXs='0' size='8' sizeMd='8'  sizeXs='11'>
                  <p className="headline-two">Passionate Coder and Designer</p>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow className="social">
            {socialPages.map((social:Social, index:number) =>{
              return (
                <IonCol key={index} className="round-col" offset={index == 0 ? '1' : '0'} offsetXs='1.5' size='2' sizeXs='2'>
                  <a className={social.title.toLowerCase().replace(' ', '-') + " social-item"} href={social.url} target="_blank" title={social.title}>
                  <span className="title">{social.title}</span>
                  {social.iosIcon !== undefined ? 
                    <IonIcon title={social.title} src={social.iosIcon} size='101' />
                  : null}
                  {social.image !== undefined ? 
                    <img title={social.title} src={social.image}  width={22.5} />
                  : null}
                 
                  </a>
                </IonCol>
              )   
            })}
          </IonRow>
        </IonGrid>
      </IonHeader>
    );
  };
  
  //Menu.defaultProps = defaultProps;
  
  export default Header;
  